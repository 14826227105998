import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import MaterialDashboard from "./material-dashboard";
import { createPinia } from "pinia";
import { LoadingPlugin } from "vue-loading-overlay";
import { LOADER_CONFIG } from "./utils/Config";
import "vue-loading-overlay/dist/css/index.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/firebaseInit";
import "@/assets/css/style.css";
import { i18n } from "./translation";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// TODO uncomment when chart data timezone issue resolved
// import moment from "moment-timezone";

// TODO uncomment when chart data timezone issue resolved
// moment.tz.setDefault("America/Montreal");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

const appInstance = createApp(App);
appInstance.use(LoadingPlugin, LOADER_CONFIG);

appInstance.use(pinia);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.use(i18n);
appInstance.mount("#app");
