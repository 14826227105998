import { auth } from "@/firebaseInit";
import { createRouter, createWebHistory } from "vue-router";
import ROUTES from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: ROUTES,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.meta.title && typeof to.meta.title === "string") {
    document.title = "McGill - " + to.meta.title;
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!to.matched.length) {
    next("/sign-in");
  } else if (requiresAuth) {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        next("/sign-in");
      } else {
        next();
      }
    });
  } else {
    auth.onAuthStateChanged((user) => {
      if (user) {
        next("/studies");
      } else {
        next();
      }
    });
  }
});

export default router;
