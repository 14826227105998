import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, _mergeProps({
    "data-bs-toggle": _ctx.collapse ? 'collapse' : '',
    to: _ctx.collapse ? `#${_ctx.collapseRef}` : _ctx.collapseRef,
    "aria-controls": _ctx.collapseRef,
    "aria-expanded": _ctx.isExpanded,
    class: ["nav-link", _ctx.getRoute() === _ctx.collapseRef ? `active bg-gradient-info` : '']
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeLocalStorage()))
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["text-center d-flex align-items-center justify-content-center", _ctx.isRTL ? ' ms-2' : 'me-2'])
      }, [
        _renderSlot(_ctx.$slots, "icon")
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(["nav-link-text", _ctx.isRTL ? ' me-1' : 'ms-1'])
      }, _toDisplayString(_ctx.navText), 3)
    ]),
    _: 3
  }, 16, ["data-bs-toggle", "to", "aria-controls", "aria-expanded", "class"]))
}