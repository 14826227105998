
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { settingStore } from "@/store/setting";
export default defineComponent({
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    removeLocalStorage() {
      if (this.collapseRef == "sign-in") localStorage.clear();
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  computed: {
    ...mapState(settingStore, ["isRTL", "color"]),
  },
});
