
import { defineComponent } from "vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import Sidenav from "@/examples/Sidenav/index.vue";
import { settingStore } from "@/store/setting";
import { useAuthStore } from "@/store/auth";
import Loader from "@/components/Loader.vue";
import { mapActions, mapState } from "pinia";

export default defineComponent({
  name: "App",
  components: {
    Sidenav,
    Navbar,
    Loader,
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    ...mapActions(settingStore, [
      "toggleConfigurator",
      "navbarMinimize",
      "openDisplay",
    ]),
    ...mapState(useAuthStore, ["authUser"]),
    toggleSidenav() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    ...mapState(settingStore, [
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    try {
      if (this.authUser()) {
        this.openDisplay();
      }
      const sidenav = document.getElementsByClassName("g-sidenav-show")[0];
      if (window.innerWidth > 1200) {
        sidenav.classList.add("g-sidenav-pinned");
      }
    } catch (err) {
      return null;
    }
  },
});
