import { Layout, List, AddEdit } from "@/views/users/Index";

export default {
  path: "/users",
  component: Layout,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "",
      name: "User",
      component: List,
      meta: {
        title: "User Listings",
      },
    },
    {
      path: "add",
      name: "User / Add ",
      component: AddEdit,
      meta: {
        title: "Add User",
      },
    },
    {
      path: "edit/:id",
      name: "User / Edit ",
      component: AddEdit,
      meta: {
        title: "Edit User",
      },
    },
  ],
};
