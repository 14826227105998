import { AddParticipants } from "@/views/participants/index";
export default {
  path: "/participant",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: ":id",
      name: "Participant / Add",
      component: AddParticipants,
      meta: {
        title: "Add Participant",
      },
    },
    {
      path: "edit/:participantId/:id",
      name: "Participant / Edit ",
      component: AddParticipants,
      meta: {
        title: "Edit Participant",
      },
    },
  ],
};
