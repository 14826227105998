import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-3 py-1 container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!

  return (_openBlock(), _createElementBlock("nav", _mergeProps({ class: "shadow-none navbar navbar-main navbar-expand-lg border-radius-xl" }, _ctx.$attrs, {
    id: "navbarBlur",
    "data-scroll": "true",
    class: _ctx.isAbsolute ? 'mt-4' : 'mt-0'
  }), [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_breadcrumbs, {
        currentPage: _ctx.currentRouteName,
        color: _ctx.color
      }, null, 8, ["currentPage", "color"])
    ])
  ], 16))
}