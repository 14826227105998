import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { ref as dbRef, onValue } from "firebase/database";

const firebaseConfig = {
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const functions = getFunctions(app);

let callback: any = null;
let metadataRef: any = null;

auth.onAuthStateChanged(async (user) => {
  const path = `metadata/${user?.uid}/refreshTime`;
  metadataRef = dbRef(db, path);

  callback = () => {
    // Force refresh to pick up the latest custom claims changes.
    // Note this is always triggered on first call. Further optimization could be
    // added to avoid the initial trigger when the token is issued and already contains
    // the latest claims.
    user?.getIdToken(true);
  };
  onValue(metadataRef, callback);
});
