
import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { settingStore } from "@/store/setting";
import { useAuthStore } from "@/store/auth";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import {
  COMPONENT_NAMES,
  SIGN_IN,
  YupValidationMessages,
} from "@/utils/Constants";
export default defineComponent({
  name: COMPONENT_NAMES.signIn,
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required(YupValidationMessages.REQUIRED.replace("@field", "Email"))
        .email(YupValidationMessages.INVALID.replace("@field", "Email")),
      password: Yup.string()
        .min(6, YupValidationMessages.PASSWORD_LENGTH)
        .required(YupValidationMessages.REQUIRED.replace("@field", "Password")),
    });

    return {
      schema,
      ...SIGN_IN,
    };
  },
  beforeMount() {
    this.closeDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleHideConfig();
  },
  methods: {
    async onSubmit(values: any, actions: any) {
      let loader = this.$loading.show({ active: true });
      try {
        const data = await this.loginWithFirebase(
          values.email,
          values.password
        );
        loader.hide();

        actions.setFieldError("password", this.$t("labels.invalidCredentials"));
        if (data) {
          this.openDisplay();
          this.$router.push("/studies");
        }
      } catch (err) {
        loader.hide();
        return null;
      }
    },

    ...mapActions(useAuthStore, ["loginWithFirebase"]),
    ...mapActions(settingStore, [
      "toggleHideConfig",
      "closeDisplay",
      "openDisplay",
    ]),
  },
});
