import moment from "moment-timezone";
import { SelectedStudyInterface } from "./Interfaces/Study";

export const findNearestDate = (
  hbA1cArray: { testDate: string; name: string }[],
  currentDate: string
): string => {
  let nearestDate = { testDate: "", name: "" };
  let nearestDateDiff = Infinity;

  hbA1cArray.forEach((hbA1c: { testDate: string; name: string }) => {
    const hbA1cDate = new Date(hbA1c.testDate);
    const current = new Date(currentDate);
    if (hbA1cDate <= current) {
      const diff = Math.abs(current.getTime() - hbA1cDate.getTime());
      if (diff < nearestDateDiff) {
        nearestDate = hbA1c;
        nearestDateDiff = diff;
      }
    }
  });
  if (nearestDate.testDate) {
    return `${nearestDate.name}% (${moment(nearestDate.testDate).format(
      "DD-MMM-YYYY"
    )})`;
  }
  return "";
};

export const calculateDiagnoseDate = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
) => {
  if (startDate && endDate) {
    var a = moment(endDate);
    var b = moment(startDate);
    var years = a.diff(b, "year");
    b.add(years, "years");
    var months = a.diff(b, "months");
    b.add(months, "months");
    return `${years} years ${months} months`;
  }
  return "";
};

export const convertIntervention = (
  intervention: Array<{
    name: string;
    startDate: null | Date;
    endDate: null | Date;
    timestamp: number;
  }>
): Array<{ dateRange: string; interventionName: string }> => {
  return intervention?.map((item) => {
    if (item?.startDate && item?.endDate) {
      const start = new Date(item.startDate);
      const end = new Date(item.endDate);
      const formattedStart = moment(start).format("DD-MMM-YYYY");
      const formattedEnd = moment(end).format("DD-MMM-YYYY");

      return {
        dateRange: `${formattedStart} to ${formattedEnd}`,
        interventionName: item?.name || "",
      };
    }
    return {
      dateRange: "",
      interventionName: item?.name || "",
    };
  });
};

export const updateParticipantsIntervention = async (
  allParticipants: any,
  data: SelectedStudyInterface
) => {
  for (let participantId in allParticipants) {
    let intervention = allParticipants[participantId]?.intervention;
    intervention = updateIntervention(intervention, data);

    const compareIntervention = data?.intervention;
    for (let compKey in compareIntervention) {
      if (
        !allParticipants[participantId].intervention ||
        !allParticipants[participantId].intervention[compKey]
      ) {
        allParticipants[participantId].intervention = {
          ...allParticipants[participantId].intervention,
          [compKey]: compareIntervention[compKey],
        };
      }
    }
  }
};

function updateIntervention(intervention: any, data: SelectedStudyInterface) {
  for (let intKey in intervention) {
    if (data.hasOwnProperty("intervention")) {
      if (!data?.intervention?.[intKey]) {
        delete intervention[intKey];
      } else {
        intervention[intKey] = {
          ...intervention[intKey],
          name: data?.intervention[intKey].name,
        };
      }
    } else {
      delete intervention[intKey];
    }
  }
  return intervention;
}
