import { STORE_NAMES } from "@/utils/Constants";
import { defineStore } from "pinia";
import { Alert } from "@/utils/Interfaces/Common";

export const useAlertStore = defineStore(STORE_NAMES.alert, {
  state: () => {
    return {
      alert: null,
    } as Alert;
  },

  actions: {
    success(message: string) {
      this.alert = { message, type: "alert-success" };
    },
    error(message: string) {
      this.alert = { message, type: "alert-danger" };
    },
    clear() {
      this.alert = null;
    },
  },
});
