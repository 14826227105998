
import SidenavList from "./SidenavList.vue";
const logo = require("@/assets/img/mcgill-logo.png");
const logoDark = require("@/assets/img/logo-ct-dark.png");
import { mapState } from "pinia";
import { settingStore } from "@/store/setting";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
    };
  },
  computed: {
    ...mapState(settingStore, ["isRTL", "sidebarType", "isDarkMode"]),
  },
};
