import { defineStore } from "pinia";
import { STORE_NAMES } from "@/utils/Constants";
import { State } from "@/utils/Interfaces/Common";

const initialValues = {
  hideConfigButton: false,
  isPinned: true,
  showConfig: false,
  sidebarType: "bg-gradient-dark",
  isRTL: false,
  color: "success",
  isNavFixed: false,
  isAbsolute: false,
  showNavs: true,
  showSidenav: false,
  showNavbar: false,
  showFooter: false,
  showMain: true,
  isDarkMode: false,
  navbarFixed:
    "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
  absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
  loader: false,
};

export const settingStore = defineStore(STORE_NAMES.setting, {
  state: () => initialValues as State,
  actions: {
    setColor() {
      this.color = "warning";
    },
    toggleConfigurator() {
      this.showConfig = !this.showConfig;
    },
    navbarMinimize() {
      const sidenav_show: any = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        this.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        this.isPinned = false;
      }
    },
    navbarFixed() {
      if (this.isNavFixed === false) {
        this.isNavFixed = true;
      } else {
        this.isNavFixed = false;
      }
    },
    closeDisplay() {
      this.showNavbar = false;
      this.showSidenav = false;
      this.showFooter = false;
    },
    openDisplay() {
      this.showNavbar = true;
      this.showSidenav = true;
      this.showFooter = true;
    },
    toggleHideConfig() {
      this.hideConfigButton = !this.hideConfigButton;
    },
  },
});
