import "./assets/js/nav-pills.js";
import "./assets/js/ripple-effect.js";
import "./assets/js/material-input.js";
import "./assets/js/tooltip.js";
import "./assets/js/dark-mode.js";
import "./assets/js/font-awesome.js";
import "./assets/scss/material-dashboard.scss";

export default {
  install() {},
};
