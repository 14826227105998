
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState, mapActions } from "pinia";
import { settingStore } from "@/store/setting";
import { defineComponent } from "vue";

export default defineComponent({
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav();
  },
  methods: {
    ...mapActions(settingStore, ["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapState(settingStore, ["isRTL", "isAbsolute"]),
    currentRouteName() {
      return this.$route;
    },
  },
});
