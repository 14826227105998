import * as Yup from "yup";
import { YupValidationMessages, RESEARCHER_ROLE } from "@/utils/Constants";
import moment from "moment-timezone";

export const isValid = (timeSlots: any) => {
  if (!timeSlots) {
    return { flag: true, message: "", path: "" };
  }

  for (let i = 0; i < timeSlots.length; i++) {
    const slot1 = timeSlots[i];

    if (!slot1.startDate || !slot1.endDate) {
      continue;
    }

    const start1 = moment(slot1.startDate, "HH:mm");
    const end1 = moment(slot1.endDate, "HH:mm");

    for (let j = 0; j < timeSlots.length; j++) {
      if (i === j) {
        continue;
      }

      const slot2 = timeSlots[j];

      if (!slot2.startDate && !slot2.endDate) {
        continue;
      }

      const start2 = slot2.startDate ? moment(slot2.startDate, "HH:mm") : null;

      if (start2 && start2.isBetween(start1, end1, undefined, "[]")) {
        return {
          flag: false,
          message: `Overlapping time in intervention ${j + 1}`,
          path: `intervention[${j}].startDate`,
        };
      }

      const end2 = slot2.endDate ? moment(slot2.endDate, "HH:mm") : null;

      if (end2 && end2.isBetween(start1, end1, undefined, "[]")) {
        return {
          flag: false,
          message: `Overlapping time in intervention ${j + 1}`,
          path: `intervention[${j}].endDate`,
        };
      }
    }
  }

  return { flag: true, message: "", path: "" };
};

export const Add_Participant_Schema = Yup.object().shape({
  id: Yup.string().required(
    YupValidationMessages.REQUIRED.replace("@field", "ID")
  ),
  gender: Yup.string().nullable(),
  dob: Yup.date().max(moment(), "You can't be born in the future!").nullable(),
  diagnoseDate: Yup.date()
    .max(moment(), "Diagnose date can't be future date!")
    .nullable(),
  intervention: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(
          YupValidationMessages.REQUIRED.replace("@field", "Intervention name")
        ),
        startDate: Yup.date().nullable(),
        endDate: Yup.date()
          .min(Yup.ref("startDate"), "End date can't be before Start date")

          .nullable(),
      })
    )
    .test(function (value) {
      const {
        message,
        path,
        flag,
      }: { message: string; path: string; flag: boolean } = isValid(value);
      if (!flag) {
        return this.createError({
          message,
          path,
        });
      }
      return true;
    }),
  bloodTest: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      testDate: Yup.date()
        .max(moment(), "Test date can't be future date!")
        .nullable()
        .default(null),
    })
  ),
  height: Yup.number()
    .positive()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    ),
  weight: Yup.number()
    .positive()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    ),
  admissionDate: Yup.date()

    .max(moment(), "Admission date can't be future date!")
    .nullable(),
  studyEndDate: Yup.date()
    .nullable()
    .test(
      "studyEndDate",
      "Study end date must be greater than end date of last intervention",
      function (value) {
        const interventions = this.parent.intervention;
        if (interventions && interventions.length > 0) {
          const lastIntervention = interventions[interventions.length - 1];
          if (
            lastIntervention.endDate &&
            value &&
            value <= lastIntervention.endDate
          ) {
            return false;
          }
        }
        return true;
      }
    ),
});

export const Add_Study_Schema = Yup.object().shape({
  name: Yup.string().required(
    YupValidationMessages.REQUIRED.replace("@field", "Name")
  ),
  intervention: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(
          YupValidationMessages.REQUIRED.replace("@field", "Intervention")
        ),
      })
    )
    .strict(),
});

const testCallbackFunc = (val?: string) => {
  if (val === undefined) {
    return true;
  }
  return val.toString().length <= 50;
};

export const Add_User_Schema = Yup.object().shape({
  firstName: Yup.string()
    .required(YupValidationMessages.REQUIRED.replace("@field", "First Name"))
    .test(
      "firstName",
      "Must be exactly 50 or less characters",
      testCallbackFunc
    ),
  lastName: Yup.string()
    .required(YupValidationMessages.REQUIRED.replace("@field", "Last Name"))
    .test(
      "lastName",
      "Must be exactly 50 or less characters",
      testCallbackFunc
    ),
  email: Yup.string()
    .required(YupValidationMessages.REQUIRED.replace("@field", "Email"))
    .email(YupValidationMessages.INVALID.replace("@field", "Email")),
  role: Yup.string().required(
    YupValidationMessages.REQUIRED.replace("@field", "Role")
  ),
  studies: Yup.array().when("role", {
    is: (role: string) => role === RESEARCHER_ROLE,
    then: Yup.array()
      .of(Yup.string())
      .required(YupValidationMessages.REQUIRED.replace("@field", "Studies")),
    otherwise: Yup.array().of(Yup.string()),
  }),
});
