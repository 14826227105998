import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3620a8c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "container top-0 position-sticky z-index-sticky" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "mt-0 main-content" }
const _hoisted_6 = { class: "page-header min-vh-100" }
const _hoisted_7 = { class: "container-fluid" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-8 d-lg-flex d-none h-100 my-auto pe-0 ps-0 position-absolute top-0 start-0 text-center justify-content-center flex-column left-image" }
const _hoisted_10 = { class: "col-xl-3 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-7 right-inputs" }
const _hoisted_11 = { class: "card card-plain" }
const _hoisted_12 = { class: "pb-0 card-header bg-transparent mb-5" }
const _hoisted_13 = { class: "font-weight-bolder" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "form-group form-group-lg" }
const _hoisted_17 = { class: "form-row" }
const _hoisted_18 = { class: "input-group-outline" }
const _hoisted_19 = { class: "form-label" }
const _hoisted_20 = { class: "invalid-feedback" }
const _hoisted_21 = { class: "form-row mt-4" }
const _hoisted_22 = { class: "input-group-outline" }
const _hoisted_23 = { class: "invalid-feedback" }
const _hoisted_24 = { class: "text-center mt-2" }
const _hoisted_25 = {
  class: "btn mb-0 my-3 bg-gradient-info w-100 btn-lg",
  type: "submit"
}
const _hoisted_26 = { class: "px-1 pt-0 text-center card-footer px-lg-2" }
const _hoisted_27 = { class: "mx-auto mb-4 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_navbar, {
            isBlur: "blur my-3 py-2 mt-4 start-0 end-0 mx-4 shadow blur border-radius-lg",
            btnBackground: "bg-gradient-success",
            darkMode: true
          })
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_5, [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: _normalizeClass(["position-relative h-100 border-radius-lg d-flex flex-column justify-content-center", _ctx.resize]),
                  style: _normalizeStyle({
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/illustrations/signin.png') +
                      ')',
                    backgroundPosition: 'left center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  })
                }, null, 6)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.titleHeading), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_Form, {
                      onSubmit: _ctx.onSubmit,
                      "validation-schema": _ctx.schema
                    }, {
                      default: _withCtx(({ errors }) => [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.emailText), 1),
                              _createVNode(_component_Field, {
                                name: "email",
                                type: "text",
                                class: _normalizeClass(["form-control input-lg", { 'is-invalid': errors.email }]),
                                placeholder: "email"
                              }, null, 8, ["class"]),
                              _createElementVNode("div", _hoisted_20, _toDisplayString(errors.email), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("label", null, _toDisplayString(_ctx.passwordText), 1),
                            _createVNode(_component_Field, {
                              name: "password",
                              type: "password",
                              placeholder: "password",
                              class: _normalizeClass(["form-control input-lg", { 'is-invalid': errors.password }])
                            }, null, 8, ["class"]),
                            _createElementVNode("div", _hoisted_23, _toDisplayString(errors.password), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.title), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["onSubmit", "validation-schema"])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("p", _hoisted_27, [
                      _createTextVNode(_toDisplayString(_ctx.forgotPassWordText) + " ", 1),
                      _createVNode(_component_router_link, {
                        to: "forgot-password",
                        class: "text-info text-gradient font-weight-bold"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.forgotButtonLink), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}