import { StudyDetail, ListStudy } from "@/views/study/index";
import { ParticipantDetail } from "@/views/participants/index";
export default {
  path: "",
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "/studies",
      name: "Study",
      component: ListStudy,
      meta: {
        title: "Study Listings",
      },
    },
    {
      path: "studies/:id",
      name: "Study / Detail",
      component: StudyDetail,
      meta: {
        title: "Study Detail",
      },
    },
    {
      path: "/studies/:study_id/participants/:participant_id",
      name: "Participant / Detail",
      component: ParticipantDetail,
      meta: {
        title: "Participant Detail",
      },
    },
  ],
};
