export const LOADER_CONFIG = {
  canCancel: true,
  color: "#000000",
  loader: "spinner",
  width: 64,
  height: 64,
  backgroundColor: "#ffffff",
  opacity: 0.5,
  zIndex: 999,
};

