
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState, mapActions } from "pinia";
import { settingStore } from "@/store/setting";
import { useAuthStore } from "@/store/auth";
import { OWNER_ROLE } from "@/utils/Constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      OWNER_ROLE,
    };
  },
  components: {
    SidenavCollapse,
  },
  methods: {
    ...mapActions(settingStore, ["toggleConfigurator", "closeDisplay"]),
    ...mapActions(useAuthStore, ["logoutUser"]),
    async logout() {
      try {
        this.closeDisplay();
        const response = await this.logoutUser();
        if (response) {
          this.$router.push("/sign-in");
        }
      } catch (err) {
        return null;
      }
    },
  },

  computed: {
    ...mapState(settingStore, ["isRTL"]),
    ...mapState(useAuthStore, ["authUser"]),
  },
});
