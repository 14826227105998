import { ConvertedParticipants } from "./../utils/Interfaces/Participants";
import { convertToArray } from "@/utils/Common";
import {
  STUDY_RESPONSE,
  PATH,
  RESEARCHER_ROLE,
  STORE_NAMES,
  STUDY_STATUS,
} from "@/utils/Constants";
import { defineStore } from "pinia";
import {
  addStudies,
  getData,
  getStudyById,
  getParticipants,
  updateParticipant,
} from "../utils/Api";
import { nanoid } from "nanoid";
import {
  StudyInterface,
  SelectedStudyInterface,
} from "@/utils/Interfaces/Study";
import { updateParticipantsIntervention } from "@/utils/ParticipantsFunction";
export const useStoreStudy = defineStore(STORE_NAMES.study, {
  state: () => {
    return {
      study: [],
      filteredStudies: [],
      studyLoaded: false,
      selectedStudy: {},
    } as StudyInterface;
  },

  actions: {
    async init() {
      try {
        await this.studyListing(PATH.study);
      } catch (err) {
        if (err) throw err;
      }
    },

    deleteSelectedStudy() {
      try {
        this.selectedStudy = {};
      } catch (err) {
        if (err) throw err;
      }
    },

    setSelectedStudy(item: SelectedStudyInterface) {
      try {
        this.selectedStudy = {
          ...item,
          intervention: item.intervention,
        };
      } catch (error) {
        if (error) throw error;
      }
    },

    async addStudy<T>(data: SelectedStudyInterface): Promise<T | null | void> {
      try {
        const id = nanoid();
        return addStudies(data, PATH.study, id);
      } catch (error) {
        if (error) throw error;
      }
    },

    async studyListing<T>(path: string, filterType?: string, authUser?: any) {
      try {
        const data = await getData(PATH.study);
        if (!data) {
          throw new Error(STUDY_RESPONSE.NO_STUDY);
        }
        const convertedData = convertToArray(data);
        const tempStudy = convertedData;
        let tempFilterStudy = convertedData;
        if (filterType && filterType !== STUDY_STATUS.All)
          tempFilterStudy = convertedData.filter(
            (item) => item.status == filterType
          );
        if (authUser?.role === RESEARCHER_ROLE) {
          if (authUser?.studies.length) {
            tempFilterStudy = authUser.studies
              .map((studyId: string[]) => {
                return tempFilterStudy.find((item) => item.key == studyId);
              })
              .filter(Boolean);
          } else {
            tempFilterStudy = [];
          }
        }
        this.study = tempStudy;
        if (filterType || authUser?.role === RESEARCHER_ROLE)
          this.filteredStudies = tempFilterStudy;
        this.studyLoaded = false;
        return this.study;
      } catch (error) {
        if (error) throw error;
      }
    },

    async getSelectedStudy(key: string) {
      try {
        const data = await getStudyById(PATH.study, key);
        if (data) {
          this.selectedStudy = { ...data, key: key };
          return null;
        }
        return null;
      } catch (error) {
        if (error) throw error;
      }
    },

    async editStudy<T>(
      data: SelectedStudyInterface,
      key: string
    ): Promise<T | null | void> {
      try {
        const response = await addStudies(data, PATH.study, key);
        const allParticipants = await getParticipants(PATH.participants, key);
        await updateParticipantsIntervention(allParticipants, data);
        if (allParticipants) {
          await this.updateAllParticipants(PATH.participants, allParticipants);
        }
        return response;
      } catch (error) {
        if (error) throw error;
      }
    },

    async updateAllParticipants(path: string, allParticipants: any) {
      try {
        const promises: any = [];
        Object.entries(allParticipants).forEach((item) => {
          promises.push(
            updateParticipant(path, item[0], item[1] as ConvertedParticipants)
          );
        });
        await Promise.all(promises);
      } catch (err) {
        throw err;
      }
    },

    async getStudyWithFilter(filterType: string) {
      try {
        const data = await getData(PATH.study);
        const convertedData = convertToArray(data);
        let tempStudy = convertedData;
        if (filterType && filterType !== STUDY_STATUS.All)
          tempStudy = convertedData.filter((item) => item.status == filterType);

        this.study = tempStudy;
        this.studyLoaded = false;
        return this.study;
      } catch (error) {
        if (error) throw error;
      }
    },
  },
  getters: {
    getStudy(state) {
      return state?.study?.map((item: SelectedStudyInterface) => {
        return {
          label: item?.name,
          value: item?.key,
        };
      });
    },
  },
});
