import { defineStore } from "pinia";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { auth, functions } from "@/firebaseInit";
import {
  getData,
  addData,
  deleteUser,
  getUser,
  updateUser,
  getUsersWithFilter,
} from "../utils/Api";
import { STORE_NAMES, PATH } from "@/utils/Constants";
import { IUser } from "@/utils/Interfaces/Auth";
import useStorage from "@/utils/UseStorage";
import { objectToArray } from "@/utils/Common";
import { httpsCallable } from "firebase/functions";

const {
  storage: { setItem },
} = useStorage(localStorage);

export const useAuthStore = defineStore(STORE_NAMES.auth, {
  state: () =>
    ({
      userList: [],
      user: {},
      userId: "",
      isLoggedIn: false,
      successMessage: "",
      errorMessage: "",
      forgotSuccessMessage: false,
      authUser: null,
      uid: "",
    } as IUser),
  persist: {
    paths: ["authUser", "isLoggedIn", "uid"],
  },
  actions: {
    async loginWithFirebase(email: string, password: string): Promise<boolean> {
      try {
        const response = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const responseUser = await this.getUserById(
          PATH.user,
          response.user.uid,
          true
        );
        this.authUser = responseUser;
        this.isLoggedIn = true;
        this.uid = response.user.uid;
        setItem("uid", response.user.uid);
        return true;
      } catch (error) {
        return false;
      }
    },

    async logoutUser() {
      try {
        await signOut(auth);
        this.authUser = null;
        this.isLoggedIn = false;
        this.uid = "";
        localStorage.removeItem("uid");
        return true;
      } catch (err) {
        return false;
      }
    },

    async createUser<T>(
      data: any,
      sendMailKey?: boolean
    ): Promise<T | null | void> {
      try {
        const createUser = httpsCallable(functions, "createUser");
        const updateUserRole = httpsCallable(functions, "setCustomClaims");
        const userData: {
          email: string;
          password: string;
          role?: string;
        } = {
          email: data.email,
          password: data.password,
        };
        if (data?.role) {
          userData["role"] = data.role;
        }
        const response: any = await createUser(userData);

        const updateData: any = await updateUserRole({
          uid: response?.data?.uid,
          role: data.role ?? "",
        });
        if (response?.data) {
          if (sendMailKey) {
            return null;
          }
          if (response?.data?.uid) {
            data["user_id"] = response?.data?.uid;
            const userResponse = await addData(response.data.uid, data);
            await this.sendEmail(data.email);
            return userResponse;
          }
        }
        return null;
      } catch (error) {
        if (error) throw error;
      }
    },

    async sendEmail(email: string): Promise<boolean> {
      try {
        await sendPasswordResetEmail(auth, email, {
          url: process.env.VUE_APP_REDIRECT_URL,
          handleCodeInApp: true,
        });
        this.forgotSuccessMessage = true;
        return true;
      } catch (error) {
        return false;
      }
    },

    async getUsers<T>() {
      try {
        const data = await getData<T>(PATH.user);
        const convertedData = objectToArray(data);
        this.userList = convertedData;
        return convertedData;
      } catch (error) {
        if (error) throw error;
      }
    },

    async getUserById(path: string, Id: string, authUser?: boolean) {
      try {
        const data = await getUser(path, Id);
        if (!authUser) {
          this.user = {
            ...data,
            studies: data.studies,
          };
          this.userId = Id;
        }
        delete data?.password;
        return data;
      } catch (error) {
        if (error) throw error;
      }
    },

    async editUser<T>(data: any): Promise<boolean | T | undefined> {
      try {
        data["user_id"] = this.userId;
        const response = await updateUser(this.userId, data);
        const updateUserRole = httpsCallable(functions, "setCustomClaims");
        if (this.user?.role && this.user?.role !== data.role)
          await updateUserRole({
            uid: this.userId,
            role: data.role ?? "",
          });
        return response;
      } catch (error) {
        if (error) throw error;
      }
    },

    async removeUser() {
      try {
        this.user = null;
        this.userId = "";
      } catch (error) {
        if (error) throw error;
      }
    },

    async deletingUser(Id: string) {
      try {
        const userDeleteFunction = httpsCallable(functions, "deleteAuthUser");
        const response = await userDeleteFunction({
          uid: Id,
        });
        if (response) {
          await deleteUser(Id);
        }
        if (Id === this.uid) {
          window.location.href = process.env.VUE_APP_REDIRECT_URL;
          return true;
        }
        await this.getUsers();
      } catch (error: any) {
        if (error) throw error;
      }
    },

    async getAuthUser(path: string, Id: string) {
      try {
        const data = await getUser(path, Id);
        if (data) {
          delete data?.password;
          this.authUser = data;
        }
        return true;
      } catch (error) {
        if (error) throw error;
      }
    },

    async getUserWithFilter(path: string, filterType: string) {
      try {
        const data = await getUsersWithFilter(path, filterType);
        const convertedData = objectToArray(data);
        this.userList = convertedData;
        return data;
      } catch (error) {
        if (error) throw error;
      }
    },
  },
});
