import { RouteRecordRaw } from "vue-router";
import participantRoutes from "./participant.routes";
import studyRoutes from "./study.routes";
import usersRoutes from "./user.routes";
import SignIn from "../views/SignIn.vue";
import ForgotPasswordVue from "@/views/ForgotPassword.vue";
import { default as PageNotFound } from "@/views/PageNotFound.vue";
const ROUTES: Array<RouteRecordRaw> = [
  { ...usersRoutes },
  /**
   * study routes
   */
  { ...studyRoutes },

  /**
   * participants routes
   */
  { ...participantRoutes },

  /**
   * Onboarding Routes
   */
  { path: "/", redirect: { path: "/studies" } },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: {
      requiresAuth: false,
      title: "Sign-In",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-Password",
    component: ForgotPasswordVue,
    meta: {
      requiresAuth: false,
      title: "ForgotPassword",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
  },
];

export default ROUTES;
