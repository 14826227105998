export type Nullable<T> = T | null | undefined;

const getItemClosure = (storage: Storage) => {
  return function getItem<T>(key: string): Nullable<T> {
    let temp;
    try {
      temp = storage.getItem(key);
      if (!temp) {
        return null;
      }

      return JSON.parse(temp) as T;
    } catch {
      return (temp as unknown) as T;
    }
  };
};

const setItemClosure = (storage: Storage) => {
  return function setItem(key: string, item: any): void {
    storage.setItem(key, JSON.stringify(item));
  };
};

export default function useStorage(storage:Storage) {
    return {
      storage: {
        setItem: setItemClosure(storage),
        getItem: getItemClosure(storage),
      },
    };
  }
  
