
import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { settingStore } from "@/store/setting";
import { useAuthStore } from "@/store/auth";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "forgot-password",
  components: {
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });
    return {
      schema,
      forgotSuccessMessage: false,
      msg: "If this user exists, the reset link will be sent to the email address provided.",
    };
  },
  beforeMount() {
    this.closeDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.closeDisplay();
    this.toggleHideConfig();
  },
  methods: {
    async onSubmit(values: any) {
      let loader = this.$loading.show({ active: true });
      try {
        await this.sendEmail(values.email);
        this.forgotSuccessMessage = true;
        loader.hide();
      } catch (err) {
        loader.hide();
        return null;
      }
    },
    async loginPage() {
      this.$router.push("/sign-in");
    },
    ...mapActions(useAuthStore, ["sendEmail"]),
    ...mapActions(settingStore, ["toggleHideConfig", "closeDisplay"]),
  },
});
